.container {
    background-color: var(--background-color);
    color: var(--text-color);
    min-height: 100vh;
    /* padding: 20px; */
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.center-column {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95vw;
    margin: 0 auto;
}

.title {
    text-align: center;
    margin: 20px 0px;
    margin-bottom: 0px;
}

.header {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
}

h2 {
    margin: 0;
    padding: 0;
    font-size: 1.5em;
    text-align: center;
}

.upload-container {
    display: flex;
    margin-bottom: 20px;
    flex-direction: column;
    align-items: center;
}

.button-and-name {
    display: flex;
    /* margin-bottom: 20px;
    flex-direction: column;
    align-items: center; */
}

.hiddenInput {
    display: none;
}

.customFileButton {
    background-color: var(--button-color); 
    color: var(--button-text-color);
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    /* margin-right: 10px; */
    height: fit-content;
}

.audio-estimates {
    padding-top: 20px;
}

.BPM-label {
    margin-right: 10px;
}

.BPM-input {
    width: 100px;
}

.BPM-input-area {
    margin: 10px;
}

/* .filename {
    padding: 10px;
} */

.customFileButton:hover {
    background-color: var(--button-hover-color);
}

.uploadButton {
    background-color: var(--button-hover-color);
    color: var(--button-text-color);
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    opacity: 0;
    width: fit-content;
}

.uploadButton.active {
    background-color: var(--button-color);
    color: var(--button-text-color);
    opacity: 1;
}

.uploadButton:hover:enabled {
    background-color: var(--button-hover-color);
}

.audio-player {
    margin-top: 0;
}

.submitButton {
    background-color: var(--button-color);
    color: var(--button-text-color);
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
    /* font-size: 1.2em; */
}

.submitButton:hover {
    background-color: var(--button-hover-color);
}

.tempo-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    width: 100%;
}

.timing-selector {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}


.timingButton {
    background-color: var(--button-color);
    color: var(--button-text-color);
    padding: 10px 20px;
    border: none;
    width: fit-content;
    width: 200px;
    margin: 10px;
}

.timingButton:hover {
    background-color: var(--button-hover-color);
}

.active {
    background-color: var(--disabled);
}

.active:hover {
    background-color: var(--disabled);
}

.tempo-tapper-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.audio-image {
    width: 300px;
    height: 128px;
}

hr {
    border: none; 
    border-top: 2px solid rgb(78, 78, 78);
    width: 100%;
    margin: 20px auto;
}

iframe {
    color: black;
    border: 1px solid black;
}