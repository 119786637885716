
.dropdown-container {
    /* position: relative;
    display: inline-block; */
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .dropdown-main {
    /* background-color: #3498db; */
    color: var(--secondary-color);
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    transition: background-color 0.3s ease;
  }
  
  /* .dropdown-main:hover {
    background-color: #2980b9;
  } */
  
  .dropdown-list {
    /* position: absolute;
    top: 100%;
    left: 0; */
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 3px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    margin-top: 5px;
    z-index: 1;
    width: max-content;
  }
  
  .dropdown-item {
    padding: 8px 12px;
    color: #333;
    transition: background-color 0.2s ease;
  }
  
  .dropdown-item:hover {
    background-color: #f0f0f0;
  }
  
  .dropdown-item:active {
    background-color: #e0e0e0;
  }