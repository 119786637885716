:root {
    /* --primary-color: #FF7D00;
    --secondary-color: #78290F;
    --accent-color: #73AB84;
    --text-color: #FFECD1;
    --background-color: #000501; */

    /* --button-hover-color: #05386B;
    --button-text-color: white;
    --button-color: #379683; */
    /* --button-hover-color: #78290F;
    --button-text-color: #FFECD1;
    --button-color: #FF7D00; */
    --primary-color: #3498db;
    --secondary-color: #379683;  /* #2ecc71 */
    --background-color: #333333;
    --button-hover-color: #2f7e6e; /* #379683 */
    --button-text-color: white;
    --button-color: #379683;
    --text-color: white;
    --disabled: #284842;
}

body {
    background-color: var(--background-color);
    color: var(--text-color);
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

button,
.customFileButton,
.uploadButton {
    border-radius: 4px;
}