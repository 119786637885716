.tooltip-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

/* .info-icon {
    font-size: 20px;
} */

.tooltip-box {
    position: absolute;
    /* bottom: 16%; */
    left: 24px;
    transform: translatey(-24px);
    background-color: #333;
    color: #fff;
    text-align: left;
    padding: 5px;
    border-radius: 3px;
    /* white-space: nowrap; */
    z-index: 1;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    font-size: 14px;
    width: 25vw;
    transition: opacity 0.2s ease-in-out;
}

.tooltip-box::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
}

.tooltip-box.visible {
    opacity: 1;
}

.tooltip-box.hidden {
    opacity: 0;
    pointer-events: none;
}


.tooltip-icon {
    height: 16px;
    margin: 4px 0;
}